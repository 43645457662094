<template>
  <div class="text-center">
    <v-dialog
      v-model="model"
      persistent
      :width="350"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="white--text text-center">
          {{ message }}
          <br>
          <v-progress-circular
            indeterminate
            color="white"
            class="my-3"
            :size="150"
            :width="7"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    props: {
      model: Boolean,
      message: String,
    },
    computed: {
      dialogWidth () {
        return this.$vuetify.breakpoint.smAndDown ? '80%' : '50%'
      },
    },
  }
</script>

<style scoped>

</style>
