<template>
  <div>
    <router-view />
    <base-loader
      :model="loader"
      :message="$t('app.general.processing')"
    />
  </div>
</template>

<script>
  import Vue from 'vue'
  import {
    mapState,
    mapMutations
  } from 'vuex'
  import { localize } from 'vee-validate'

  export default {
    name: 'App',
    data () {
      return {
        loader: false,
      }
    },
    computed: {
      ...mapState(['user', 'loggedIn', 'lang']),
    },
    watch: {
      '$route' (val) {
        console.log(val)
      },
    },
    created () {
      EventBus.$on('processing', () => {
        this.loader = !this.loader
      })

      const modules = [
        {
          icon: 'mdi-view-dashboard',
          title: this.$t('app.menu_title'),
          to: 'AdminDashboard',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-calendar',
          title: this.$t('app.calendario.name'),
          to: 'AdminCalendar',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-office-building',
          title: this.$t('app.sucursales.name'),
          to: 'AdminSucursales',
          visible: true,
          superadmin: true
        },
        {
          icon: 'mdi-tournament',
          title: this.$t('app.torneos.name'),
          to: 'AdminTorneos',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-soccer-field',
          title: this.$t('app.canchas.name'),
          to: 'AdminCanchas',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-account-group',
          title: this.$t('app.equipos.name'),
          to: 'AdminEquipos',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-clipboard-account',
          title: this.$t('app.arbitros.name'),
          to: 'AdminArbitros',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-arrow-decision',
          title: this.$t('app.posiciones.name'),
          to: 'AdminPosiciones',
          visible: true,
          superadmin: true
        },
        {
          icon: 'mdi-account',
          title: this.$t('app.jugadores.name'),
          to: 'AdminJugadores',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-calendar',
          title: this.$t('app.eventos.name'),
          to: 'AdminEventos',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-calendar-star',
          title: this.$t('app.jornadas.name'),
          to: 'AdminJornadas',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-account-supervisor-circle',
          title: this.$t('app.juegos_informales.name'),
          to: 'AdminJuegosInformales',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-account-circle',
          title: this.$t('app.usuarios.name'),
          to: 'AdminUsuarios',
          visible: true,
          superadmin: false
        },
        {
          icon: 'mdi-account-tie',
          title: this.$t('app.usuarios-sistema.name'),
          to: 'AdminUsuariosSistema',
          visible: true,
          superadmin: true
        },
        {
          icon: 'mdi-key',
          title: this.$t('app.roles.name'),
          to: 'AdminRoles',
          visible: true,
          superadmin: true
        },
        {
          icon: '',
          title: this.$t('app.usuarios.profile'),
          to: 'AdminUsuarioPerfil',
          visible: false,
          superadmin: false
        },
        {
          icon: 'mdi-card-account-details-star',
          title: this.$t('app.tipo_identificacion.name'),
          to: 'AdminTipoIdentificacion',
          visible: true,
          superadmin: true
        },
        {
          icon: 'mdi-cash',
          title: this.$t('app.tipo-concepto.name'),
          to: 'AdminTipoConcepto',
          visible: true,
          superadmin: true
        },
        {
          icon: 'mdi-cash',
          title: this.$t('app.conceptos_pago.name'),
          to: 'AdminConceptosPago',
          visible: true,
          superadmin: true
        },
        {
          icon: 'mdi-basketball',
          title: this.$t('app.deportes.name'),
          to: 'AdminDeportes',
          visible: true,
          superadmin: true,
        },
        {
          icon: 'mdi-calendar-clock',
          title: this.$t('app.costo-horario.name'),
          to: 'AdminCostoHorarios',
          visible: true,
          superadmin: true,
        },
        {
          icon: 'mdi-currency-usd',
          title: this.$t('app.divisas.name'),
          to: 'AdminDivisas',
          visible: true,
          superadmin: true,
        },
        {
          icon: 'mdi-cash-multiple',
          title: this.$t('app.conversion-divisas.name'),
          to: 'AdminConversionDivisas',
          visible: true,
          superadmin: true,
        },
        {
          icon: 'mdi-cash-multiple',
          title: this.$t('app.pagos.name'),
          to: 'AdminPagos',
          visible: true,
          superadmin: true,
        },
        {
          icon: 'mdi-cash-multiple',
          title: this.$t('app.formas_pago.name'),
          to: 'AdminFormasPago',
          visible: true,
          superadmin: true,
        },
      ]

      this.setModules(modules)

      Vue.http.interceptors.push(request => {
        if (this.$store.state.loggedIn) {
          request.headers.set('Authorization', 'Bearer ' + this.$store.state.token)
        }
      })

      localize(this.lang)
    },
    methods: {
      ...mapMutations({
        setModules: 'SET_MODULES'
      })
    }
  }
</script>
