<template>
  <validation-provider
    v-slot="{ errors }"
    :name="$t('app.headers.logo')"
    :rules="required ? 'required' : ''"
  >
    <v-file-input
      v-model="files"
      color="deep-purple accent-4"
      counter
      :multiple="multiple"
      :placeholder="placeholderF"
      :prepend-icon="icon"
      outlined
      :show-size="1000"
      :accept="accept"
      @change="onChangeControl"
      :error-messages="errors"
      :required="required ? true : false"
    >
      <template v-slot:selection="{ index, text }">
        <v-chip
          v-if="index < 2"
          color="deep-purple accent-4"
          dark
          label
          small
        >
          {{ text }}
        </v-chip>

        <span
          v-else-if="index === 2"
          class="overline grey--text text--darken-3 mx-2"
        >
          +{{ files.length - 2 }} {{ filesLabel }}
        </span>
      </template>
    </v-file-input>
  </validation-provider>
</template>

<script>
  export default {
    name: 'FileInput',
    props: {
      multiple: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: 'mdi-paperclip',
      },
      accept: {
        type: String,
        default: '',
      },
      required: {
        type: Boolean,
        default: false
      },
      message: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        files: [],
      }
    },
    computed: {
      placeholderF () {
        return this.placeholder == '' ? (this.multiple ? this.$t('app.general.select_your_files') : this.$t('app.general.select_a_file')) : this.placeholder
      },
      filesLabel () {
        return this.multiple ? this.$t('app.general.files') : this.$t('app.general.file')
      },
    },
    mounted () {
      EventBus.$on('success-done', () => {
        this.files = []
      })
    },
    methods: {
      onChangeControl (event) {
        this.$emit('change', this.files)
      },
    },
  }
</script>

<style scoped>

</style>
