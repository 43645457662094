<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :fullscreen="true"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
        >
          {{ $t('app.buttons.take_photo') }}
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="closeRecordWindow"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ $t('app.buttons.add') }} {{ $t('app.headers.photo').toLowerCase() }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="white"
            :disabled="!photo"
            @click="confirmPhoto"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
        <v-card-text class="no-gutters pa-0 ma-0">
          <base-video-record-window
            :width="480"
            :height="640"
            @on-photo-taken="onConfirm"
          ></base-video-record-window>
        </v-card-text>
      </v-card>
    </v-dialog>
    <template v-if="confirmed">
      {{ $t('app.general.photo_confirmed') }}
    </template>
  </div>
</template>

<script>
  export default {
    name: 'VideoRecordInput',
    data() {
      return {
        dialog: false,
        confirmed: false,
        photo: false,
      }
    },
    methods: {
      closeRecordWindow() {
        EventBus.$emit('stop-recording')
        this.dialog = false
      },
      onConfirm (photo) {
        this.photo = photo
      },
      confirmPhoto () {
        this.$emit('on-confirm', this.photo)
        this.dialog = false
        this.confirmed = true
      },
      reset () {
        this.confirmed = false
      },
    },
  }
</script>

<style scoped>

</style>
